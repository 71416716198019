import axios from 'axios'

import store from '@/store'

// create an axios instance
// eslint-disable-next-line import/no-mutable-exports
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // TODO: withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.state.authentication.authToken) {
      // let each request carry token
      config.headers.Authorization = `Bearer ${store.state.authentication.authToken}`
    }
    config.headers.Language = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'no'

    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor

service.interceptors.response.use(function (response) {
    /**
     ** Determine the request status by custom code
     ** Here is just an example
     ** You can also judge the status by HTTP Status Code
     */

      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response.data;
}, function (error) {

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error.response)
})

export default service
