<template>
<v-app id="login">
    <v-content>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4 class="login-box">
                    <v-card class="pa-3" height="350px">
                        <v-card-text>
                            <v-form class="pt-3" v-model="valid" ref="form">
                                <v-text-field append-icon="person" name="login" :label="$t('lang.email')" type="text" v-model="model.email" :rules="emailRules" readonly></v-text-field>
                                <v-text-field name="password" :label="$t('lang.password')" v-model="model.password" :rules="passwordRules" :append-icon="isPasswordVisible ? 'visibility' : 'visibility_off'" @click:append="isPasswordVisible = !isPasswordVisible" :type="isPasswordVisible ? 'text' : 'password'"></v-text-field>
                                <v-text-field name="password" :label="$t('lang.confirmPassword')" v-model="model.password_confirmation" :rules="passwordConfirmationRules" :append-icon="isConfirmPasswordVisible ? 'visibility' : 'visibility_off'" @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible" :type="isConfirmPasswordVisible ? 'text' : 'password'"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-flex lg5 md5>
                                <v-btn block color="primary" @click="updatePassword" :loading="loading">{{$t('lang.save')}}</v-btn>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex lg5 md5>
                                <v-btn block color="error" @click="$router.push('/login')">{{$t('lang.back')}}</v-btn>
                            </v-flex>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</v-app>
</template>

<script>
import { userLogin, checkPasswordLink, resetPassword } from '@/api/auth'

export default {
    data() {
        return {
            loading: false,
            valid: true,
            rememberMe: false,
            defaultLang: this.$i18n.locale,
            isPasswordVisible: false,
            isConfirmPasswordVisible: false,
            model: {
                email: '',
                password: '',
                password_confirmation: '',
                token: ''
            },
            emailRules: [
                v => !!v || this.$t('lang.required'),
                (v) => /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || this.$t('lang.invalidEmail')
            ],
            passwordRules: [
                v => !!v || this.$t('lang.required'),
                (v) => (v && v.length >= 8) || this.$t('lang.passwordChars')
            ],
            passwordConfirmationRules: [
                (v) => !!v || this.$t('lang.required'),
                (v) => v === this.model.password || this.$t('lang.matchPassword')
            ]
        }
    },

    mounted() {
        const self = this;
        const payload = {}
        payload.email = self.$route.params.email;
        self.model.token = self.$route.params.token;
        checkPasswordLink(payload).then(({ data }) => {
            if (data.expired === 1) {
                self.handleNotificationSuccessOrFainMsg(self.$t('lang.passwordLinkExpired'), 'error');
                self.$router.push('/login')
            } else {
                self.model.email = data.email
            }
        }).catch((err) => self.handleServerSideValidationError(err))
    },

    methods: {
        updatePassword() {
            const self = this
            if (self.$refs.form.validate()) {
                resetPassword(self.model).then(({ data }) => {
                    self.handleNotificationSuccessOrFainMsg(self.$t('lang.passwordUpdatesucess'), 'success');
                    self.$router.push('/login')
                }).catch((err) => self.handleServerSideValidationError(err))
            }
        }
    }
}
</script>

<style scoped>
#login {
    text-align: center;
    background-color: #e25303;
    background-image: url('/img/beige_gradient.jpg');
    width: 100% !important;
    height: 50% !important;
}
</style>
